import { navigate } from 'gatsby';
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./contacs.css";
export default function Contacts() {
  return (
    <section id="contacts">
      <Container>
        <Row>
          <Col sm={12} >
            <p className="contacts-title">Contacts</p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <p className="contact-subtitle">READY when YOU ARE</p>
          </Col>
          <Col md={8} className="contact-form">
            <Row>
              <Col xs={12} md={6}>
                <input placeholder="Your Name" />
              </Col>
              <Col xs={12} md={6}>
                <input placeholder="Email" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <input placeholder="Phone" />
              </Col>
              <Col xs={12} md={6}>
                <input placeholder="Company" />
              </Col>
            </Row>
            <Row>
              <Col>
                <textarea placeholder="Mesage" />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <button disabled="true" className="btn-send-mesagge" onClick={()=>{
                
                }}>
                  Send message{" "}
                  <img
                    className="ml-2"
                    src={require("../../assets/chevron-right.svg")}
                  />
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
        <Col md={7} className='d-flex justify-content-center'>
            <img src={require("../../assets/map.png")} className='map-image'/>
          </Col>
          <Col sm={12} md={5} className='movil-location'>
            <p>Locations</p>

            <div className='mb-2 mt-2'>
              <p><img className='mr-2' src={require('../../assets/pin.svg')}/>Kiev, Ucrania</p>
              <p><img className='mr-2' src={require('../../assets/pin.svg')}/>San Petersburgo, Russia</p>
              <p><img className='mr-2' src={require('../../assets/pin.svg')}/>Loja, Ecuador</p>
            </div>

            <div className='mt-2'>
              <p>Email : info@exmerdev.com</p>
              <p>Phone number : +343483448121</p>
            </div>
          </Col> 
        </Row>
      </Container>
    </section>
  );
}
