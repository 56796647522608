import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import Particles from "react-particles-js";
import Agency from "../components/agency/agency";
import Contacts from "../components/contacts/contacs";
import Footer from "../components/footer/footer";
import Home from "../components/home/home";
import MobileFooter from "../components/mobile-footer/mobile-footer";
import MobileNabvar from "../components/mobile-navbar/mobile-navbar";
import Navbar from "../components/nav-bar/navbar";
import { OurWork } from "../components/our-work/our-work";
import ScrollUp from "../components/shared/scroll-up";
import { ThisIsUs } from "../components/this-is-us/this-is-us";
import { WhatWeDo } from "../components/what-we-do/what-we-do";
import "./index.css";
const IndexPage = () => {
  const [enableScrollUp, setenableScrollUp] = useState(false);
  useEffect(() => {
    
    document.title = 'Exmerdev'
    window.onscroll = (e) => {
      if (window.scrollY > 1000) {
        setenableScrollUp(true);
      } else {
        setenableScrollUp(false);
      }
    };
  });
 
  
  return (
    <>
      <h1 style={{ display: "none" }}>Exmerdev</h1>
      <div className="particles-js">
        <Particles
          params={{
            particles: {
              number: {
                value: 75,
              },
              size: {
                value: 3,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
        />
      </div>
      <div className="all-components-wrapper">
        <MobileNabvar />
        <Navbar isIndex={true} />
        <Home />
        <Agency />
        <WhatWeDo />
        <OurWork />
        {/* <ThisIsUs /> */}
        <Contacts />
        <Footer />
        <MobileFooter />
        {enableScrollUp && (
          <div
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            {" "}
            <ScrollUp setenableScrollUp={setenableScrollUp} />
          </div>
        )}
      </div>
    </>
  );
};

export default IndexPage;
