import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./agency.css";
export default function Agency(props) {
  useEffect(() => {
  }, []);

  const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView();
  };
  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  };
  const trackScrolling = () => {
    const wrappedElement = document.getElementById("agency");
    if (isBottom(wrappedElement)) {
      document.removeEventListener("scroll", trackScrolling);
      scrollTo("what-we-do");
    }
  };
  return (
    <div id="agency">
      <Container>
        <Row>
          <Col>
            <h2 className="section-title mt-3">Agency</h2>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={6} className='agency-col'>
            <p className="agency-p">
              We are company that do everything you might need to bring a 
              successful digital product to life: user research, product strategy,
              UX/UI, prototyping and quality testing. 
            </p>
          </Col>
          <Col md={6} className='agency-col'>
            <p className="agency-p">
              User experience is more than a capability for us. It’s at the core of
              everything we do. We take on range from building a new product to
              redesigning an existing mobile, app, website, or customized software. 
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='img-container'>
            <img
              className="agency-image"
              src={require("../../assets/agency-picture.png")}
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md={12} className="mt-2">
            <p className="agency-p">
            We have a complete high-quality professional team that will make
            your ideas come true. We adhere to the business philosophy of 
            integrity, professionalism and efficiency, so we have won
            wide praise from our customers at home and abroad. 
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
