import React from "react";
import "./scroll-up.scss";
export default function ScrollUp(props) {
  return (
    
      <div className="container-scroll-up mr-3 mb-5">
        <div className="chevron"></div>
        <div className="chevron"></div>
        <div className="chevron"></div>
      </div>
  
  );
}
