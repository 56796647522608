import React from "react";
import "./mobile-footer.css";
export default function MobileFooter() {
  return (
    <footer className="mobile-footer">
      <div style={{display:'flex'}} className='mt-4  ml-2 p-1'>
        <p>Privacy Policy</p>
        <p className='ml-3'>Terms & Conditions</p>
      </div>
      <ul  className='mt-3 pb-3'>
        <li>
          <img
            src={require("../../assets/social-icons/telegram.svg")}
            alt="telegram icon"
            onClick={()=>{window.open('https://www.facebook.com/exmersolutions')}}
          />
        </li>
        <li>
          <img
            src={require("../../assets/social-icons/facebook.svg")}
            alt="facebook icon"
            onClick={()=>{window.open('https://www.facebook.com/exmersolutions')}}
          />
        </li>
        <li>
          <img
            src={require("../../assets/social-icons/instagram.svg")}
            alt="instagram icon"
            onClick={()=>{window.open('https://www.instagram.com/exmerdev/')}}
          />
        </li>
        <li>
          <img
            src={require("../../assets/social-icons/linkedin.svg")}
            alt="linkedin icon"
            onClick={()=>{window.open('https://www.linkedin.com/company/exmer-dev/about/')}}
          />
        </li>
      </ul>
    </footer>
  );
}
