import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./what-we-do.css";
export const WhatWeDo = () => {
  const [showingMore, setShowingMore] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const [whatWeDo, setWhatWeDo] = useState({
    data: [
      {
        id: 1,
        title: "Software Development",
        shortDescription:
          "Our main goal is to deliver a quality product, that represents you and your ideas. We work with most of the top technologies on the market, which allow us to provide the best stack that fits with your requirements... ",
        description:
          "Our main goal is to deliver a quality product, that represents you and your ideas. We work with most of the top technologies on the market, which allow us to provide the best stack that fits with your requirements.",
      },
      {
        id: 2,
        title: "Web Design",
        shortDescription:
          "We understand the importance of your business card on the web, so we create next‑level websites by strategically blending user experience and brand storytelling. Our web designers and developers create responsive websites... ",
        description:
          "We understand the importance of your business card on the web, so we create next‑level websites by strategically blending user experience and brand storytelling. Our web designers and developers create responsive websites that feel at home on any device including product landing pages, marketing sites, company intranet portals and more. We will help you at an early stage to design a business model and a strategy for its phased development.",
      },
      {
        id: 3,
        title: "Mobile Apps",
        shortDescription:
          "We start each project with a careful market research to bring measurable business benefits. Our unique experience and knowledge to create interesting and quality products is what distinguish us from other options... ",
        description:
          "We start each project with a careful market research to bring measurable business benefits. Our unique experience and knowledge to create interesting and quality products is what distinguish us from other options. We undertake the full cycle of creating mobile applications, from interface design to deployment and maintenance.",
      },
      {
        id: 4,
        title: "Technical Support",
        shortDescription:
          "We offer professional support in all areas of IT analysis, planning or procurement, as well as product maintenance and care. We will be happy to draw up a customized maintenance contract for you according to your company's requirement based on a tiered pricing and service system.",
        description:
          "We offer professional support in all areas of IT analysis, planning or procurement, as well as product maintenance and care. We will be happy to draw up a customized maintenance contract for you according to... ",
      },
      {
        id: 5,
        title: "Graphic Design",
        shortDescription:
          "For your project to achieve its goal - order graphic design development from professionals...",
        description:
          "For your project to achieve its goal - order graphic design development from professionals. Graphic design is a modern form of visual art. The EXMERDEV web studio team consists of talented designers. Studying the needs of the client, the scope of his activity and the idea of ​​the project, we create a graphic design that effectively reaches the target audience and increases brand awareness. Promotional activities in subsequent stages encourage the audience to show more interest in the brand, products and services offered.",
      },
      {
        id: 6,
        title: "Artificial Intelligence",
        shortDescription:
          "We offer a wide range of Artificial Intelligence (AI) solutions for boosting your business. We can provide you an in-depth market analysis that will help you to identify hidden relationships between clients and products... ",
        description:
          "We offer a wide range of Artificial Intelligence (AI) solutions for boosting your business. We can provide you an in-depth market analysis that will help you to identify hidden relationships between clients and products. Moreover, thanks to our Natural Language Processing algorithms, you will be able to have your chatbot specifically designed to solve the clients' general doubts. Additionally, nowadays, a recommender system is essential to every market-oriented business to improve the customer's experience. Leader companies are investing so much in these kinds of applications because of the high number of benefits. We have the knowledge and expertise to help you be part of this revolution.",
      },
    ],
    expanded: 0,
  });
  useEffect(() => {}, []);
  const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView();
  };
  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  };
  const trackScrolling = () => {
    const wrappedElement = document.getElementById("what-we-do");
    if (isBottom(wrappedElement)) {
      document.removeEventListener("scroll", trackScrolling);
      scrollTo("explore-our-work");
    }
  };
  return (
    <section id="what-we-do">
      <Container>
        <Row>
          <Col>
            <h1 className="section-title mt-3">What we do</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ul className="wwd-list-desktop mt-2">
              {whatWeDo.data.map((obj) => (
                <li key={obj.id}>
                  <Row>
                    <Col sm={4}>
                      <p className="wwd-p">{obj.title}</p>
                    </Col>
                    <Col sm={8}>
                      <p className="wwd-p">{obj.id}</p>
                      <p className="wwd-p-c">
                        {whatWeDo.expanded !== obj.id && obj.shortDescription}
                        {whatWeDo.expanded === obj.id && obj.description}
                        <a
                          className="see-more-text"
                          onClick={() => {
                            if (expanded === obj.id) {
                              setWhatWeDo({ ...whatWeDo, expanded: 0 });
                            } else {
                              setWhatWeDo({ ...whatWeDo, expanded: obj.id });
                              setExpanded(obj.id);
                            }
                          }}
                        >
                          {obj.id === expanded
                            ? "See less"
                            : "See more"}
                        </a>
                      </p>
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
            <ul className="wwd-list-mobile mt-2">
              {whatWeDo.data.map((obj) => (
                <li key={obj.id}>
                  <Row>
                    <Col sm={12}>
                      <div style={{ display: "flex", width: "100%" }}>
                        <p
                          className="see-more-text"
                          onClick={() => {
                            if (expanded === obj.id) {
                              setWhatWeDo({ ...whatWeDo, expanded: 0 });
                            } else {
                              setWhatWeDo({ ...whatWeDo, expanded: obj.id });
                              setExpanded(obj.id);
                            }
                          }}
                        >
                          <span className="mr-3">{obj.title}</span>
                          {obj.id === expanded ? (
                            <img
                              src={require("../../assets/minus-circle.svg")}
                            />
                          ) : (
                            <img
                              src={require("../../assets/plus-circle-solid.svg")}
                            />
                          )}
                        </p>
                      </div>

                      <p className="wwd-p-c">
                      {whatWeDo.expanded === obj.id && obj.description}
                      </p>
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
          </Col>
          <Col sm={4}></Col>
        </Row>
      </Container>
    </section>
  );
};
