import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./home.css";
export default function Home() {
  
  const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView();
  };
  
  return (
    <Container id="home">
      <Row>
        <Col
          md={12}
          className="home d-flex justify-content-center  align-items-center"
        >
          <div className="content-container">
            <h1>FIND YOUR INNER VISION</h1>

            <button
              className="see-more-btn"
              onClick={() => {
                scrollTo("agency");
              }}
            >
              See more
              <img src={require("../../assets/chevron-down.svg")} />
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
