import React, { useEffect, useState } from "react";
import { Accordion, Carousel, Col, Container, ListGroup, Row } from "react-bootstrap";
import "./our-work.css";
export const OurWork = () => {
  const [OurWorkArray] = useState([
    {
      id: 1,
      title: "Trading Platform",
      imgUrl: "https://i.imgur.com/auiJOKX.png",
    },
    // { id: 2, title: "Landing Page", imgUrl: "https://i.imgur.com/auiJOKX.png" },
    {
      id: 2,
      title: "CRM",
      imgUrl:
        "https://exmerdev-tmp.s3.eu-central-1.amazonaws.com/crm-screen.jpg",
    },

    <img src={require("../../assets/minus-circle.svg")} />,
  ]);
  useEffect(() => {}, []);
  const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView();
  };
  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  };
  const trackScrolling = () => {
    const wrappedElement = document.getElementById("explore-our-work");
    if (isBottom(wrappedElement)) {
      document.removeEventListener("scroll", trackScrolling);
      scrollTo("us");
    }
  };
  return (
    <section id="explore-our-work" className='mb-5 pb-1'>
      <Container>
        <Row>
          <Col sm={12}>
            <p className="section-title mt-3">Our Projects</p>
          </Col>
          <Carousel>
            {/* <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../../assets/portfolio/trading-platform.png")}
                alt="Trading Platform"
              />
              <Carousel.Caption>
                <h3 className='dark-bg-carousell'>Trading Platform</h3>
                <p className='dark-bg-carousell crousel-subtittle'>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p>
              </Carousel.Caption>
            </Carousel.Item> */}
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../../assets/portfolio/crm-screen.png")}
                alt="CRM Picture"
              />

              <Carousel.Caption>
                <h3 className='light-bg-carousell'>CRM</h3>
                <p className='light-bg-carousell crousel-subtittle'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Row>
      </Container>
    </section>
  );
};
