import React, { useState } from "react";
import "./mobile-navbar.css";
export default function MobileNabvar() {
    const[showNav,setSHowNav]=useState(false)
    const scrollTo = (id) => {
        setSHowNav(false)
        document.getElementById(id).scrollIntoView();
    
    };
  return (
    <div className='container-nav'>
      <nav className="mobile-navbar">
        <img src={require("../../assets/logo.svg")} />
        <img src={require("../../assets/menu-bars.svg")} onClick={()=>{setSHowNav(!showNav)}}/>
      </nav>
      {showNav && <nav>
          <ul>
              <li className='p-1' onClick={()=>{scrollTo("what-we-do")}}>Services</li>
              <li className='p-1' onClick={()=>{scrollTo("explore-our-work")}}>Projects</li>
              <li  className='p-1' onClick={()=>{scrollTo('contacts')}}>Contact Us</li>
              <li className='p-1' onClick={()=>{}}>Apply with us</li>
          </ul>
      </nav>}
    </div>
  );
}
